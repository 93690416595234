import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { dashboardData } from "./drawerLeftNew";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { useState, useEffect, useRef } from "react";
import { ResponsiveContainer } from "recharts";

import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { process } from "@progress/kendo-data-query";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";

import {
  ExcelExport,
  ExcelExportColumnGroup,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
// import Modal1 from "react-bootstrap/Modal";
import { Modal as BootModal } from "react-bootstrap";
import { Button, Modal } from "antd";
import { FaFileExcel } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa"; //pdf
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { Viewer } from "@grapecity/activereports-react";

const WFGrid = ({ dashboardData }) => {
  const viewerRef = React.useRef();

  // console.log("incoming data", dashboardData.lstWorkflow);
  const [workFlowData, setWorkFlowData] = useState(dashboardData.lstWorkflow);
  console.log("incoming state", workFlowData);

  for (var i = 0; i < dashboardData.lstWorkflow.length; i++) {
    dashboardData.lstWorkflow[i].subject =
      dashboardData.lstWorkflow[i].subject === undefined
        ? ""
        : dashboardData.lstWorkflow[i].subject;
  }
  const _export = useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("WorkFlow.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();
    // debugger;
    // for(let i=0;i<reportData.t1.length;i++){
    //   reportData.t1[i].mdlWegh = parseNumber(formatNumber( reportData.t1[i].mdlWegh,"n2"));
    // }

    console.log("JSON", workFlowData);
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(workFlowData);

    // console.log("Report", report);

    let parameters = [
      {
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("SelAcctNm") == ""
        //     ? 0
        //     : localStorage.getItem("SelAcctNm"),
        // ],
        Name: "pConsName",
        Value: [
          localStorage.getItem("pConsName") == "" ||
          localStorage.getItem("pConsName") == null
            ? 0
            : localStorage.getItem("pConsName"),
        ],
      },
      {
        Name: "pAccName",
        Value: [
          localStorage.getItem("SelAcctNm") == "" ||
          localStorage.getItem("SelAcctNm") == null
            ? 0
            : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
      {
        Name: "pShow",
        Value: [
          localStorage.getItem("SelAcctId") !== "0" &&
          localStorage.getItem("SelAcctId") !== undefined &&
          localStorage.getItem("SelAcctId") !== null
            ? "1"
            : localStorage.getItem("pConsId") !== "0" &&
              localStorage.getItem("pConsId") !== undefined &&
              localStorage.getItem("pConsId") !== null
            ? "2"
            : "0",

          // localStorage.getItem("pConsId") == "0"
          //   ? 0
          //   : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
    ];

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const handleClose = () => setShowModal(false);

  const pdfExport = () => {
    // setShow(true);
    setShowModal(true);
    openReport();
  };

  const themes = useSelector(selectTheme);
  const [data, setData] = useState(dashboardData.lstWorkflow);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [details, setDetails] = useState("");
  const [columnLatest, setColumnLatest] = React.useState(null);
  let closeImg = {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
  };
  const Title = ({ children }) => <div className="title">{children}</div>;

  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  const onClick = () => {
    setShow(!show);
  };

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  let defaultColumns = [
    {
      title: "Account#",
      field: "extrnlAcctId",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: -1,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Account Name",
      field: "shrtNm",
      minWidth: "200",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 0,

      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Assigned To",
      field: "assignedTo",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 1,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Comments",
      field: "comments",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 2,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Created By",
      field: "crtUser",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 3,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Related Date",
      field: "relatedDate",
      minWidth: "auto",
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 4,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Type",
      field: "servicetype",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 5,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Subject",
      field: "shortSubject",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 6,
      //columnMenu:menuWithExcelCheck
    },

    {
      title: "Status",
      field: "ticketStatus",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 7,
      //columnMenu:menuWithExcelCheck
    },
  ];

  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
  });

  const handleCommentClik = (props) => {
    setDetails(props.currentTarget.innerText);
    setShow(true);
  };
  const CustomCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    return (
      <td
        onClick={handleCommentClik}
        title={value}
        {...props.tdProps}
        colSpan={1}
        style={{
          color: props.color,
          whiteSpace: props.whiteSpace,
          textDecoration: props.textDecoration,
          cursor: props.cursor,
        }}
      >
        <a
          style={{
            color: "#007BFF",
            textDecoration: "underline",
            textDecorationColor: "#007BFF",
          }}
        >
          {value}
        </a>
      </td>
    );
  };
  const MyCustomCell = (props) => (
    <CustomCell
      {...props}
      color={"#007BFF"}
      whiteSpace={"nowrap"}
      textDecoration={"underline"}
      cursor={"pointer"}
    />
  );
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(defaultColumns);
  defaultColumns[3].className = "customCell";
  defaultColumns[3].cell = MyCustomCell;
  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }

    //newColumnsState[6].footerCell = totalSum;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    var tempcolumn = [];

    var j = 0;
    for (var i = 0; i < newColumnsState.length; i++) {
      tempcolumn[j] = newColumnsState[i];

      j++;
    }

    return tempcolumn;
    const [, ...restnew] = newColumnsState;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    if (
      localStorage.getItem("SelAcctId") > 0 &&
      newColumnsState[0].field === "accountName"
    ) {
      return restnew;
    } else {
      return newColumnsState;
    }

    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithExcelCheck;
    // newColumnsState[3].columnMenu = menuWithExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[12].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[13].columnMenu = menuWithExcelCheck;
    // newColumnsState[14].columnMenu = menuWithExcelCheck;
    // newColumnsState[15].columnMenu = menuWithExcelCheck;
    // newColumnsState[16].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[17].columnMenu = menuWithExcelCheck;
    // newColumnsState[18].columnMenu = menuWithExcelCheck;
    // newColumnsState[19].columnMenu = menuWithExcelCheck;
    // newColumnsState[20].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[21].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[22].columnMenu = menuWithExcelCheck;
    // newColumnsState[23].columnMenu = menuWithExcelCheck;
    // newColumnsState[24].columnMenu = menuWithExcelCheck;
  };
  const columnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
    setColumnLatest(props.target._columns);
  };

  const dataStateChange = (event) => {
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }


    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }
    let updatedState = createDataState(event.dataState);
    console.log("Grid Data", dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);

    if (event.dataState.sort) {
      // event.dataState.sort = event.dataState.sort.map((sort) => {
        debugger;
        if (event.dataState.sort.length>0&&event.dataState.sort[0].field == "relatedDate") {
          debugger;
          if(event.dataState.sort[0].dir==="asc"){
            
            updatedState.result.data.sort((a, b) => new Date(a.relatedDate) - new Date(b.relatedDate));
          }
          else if(event.dataState.sort[0].dir==="desc"){
            updatedState.result.data.sort((a, b) => new Date(b.relatedDate) - new Date(a.relatedDate));
          }                      
        }  
      
    }
  };


  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      const columns = defaultColumns;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === 'groupFooter') {

    //   if (cellProps.field === 'totMarket') {
    //     return (
    //       <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //         {cellProps.dataItem.aggregates.totMarket.sum}
    //       </td>
    //     );
    //   }

    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "elAcctId") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] +
              cellProps.dataItem[cellProps.shrtNm]}
          </td>
        );
      }

      if (cellProps.field === "currPrc") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketCap") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  var excelTitle =
    localStorage.getItem("pConsId") == 0 &&
    localStorage.getItem("SelAcctId") == 0
      ? ""
      : localStorage.getItem("SelAcctId") == 0
      ? "Consolidation:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");

  return (
    // <ResponsiveContainer>
    <div>
      <Modal
        title="Details"
        open={show}
        onOk={onClick}
        onCancel={onClick}
        footer={[
          <Button key="Ok" onClick={onClick}>
            Ok
          </Button>,
        ]}
      >
        {details}
      </Modal>

      <BootModal
        show={showModal}
        onHide={handleClose}
        fullscreen
        size="xl"
        style={{ zIndex: 100000 }}
      >
        <BootModal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </BootModal.Body>
        <BootModal.Footer>
          <button className="btn btn-primary btn-sm" onClick={handleClose}>
            Close
          </button>
        </BootModal.Footer>
      </BootModal>
      <ExcelExport data={data} ref={_export} fileName={"WorkFlow.xlsx"}>
        <ExcelExportColumnGroup
          title={
            excelTitle +
            "  Processing Date: " +
            localStorage.getItem("processingDate")
          }
          headerCellOptions={{
            textAlign: "left",
          }}
        >
          {stateColumns
            .sort(function (a, b) {
              return a.orderIndex - b.orderIndex;
            })
            .map((w) => {
              return (
                <ExcelExportColumn
                  field={w.field}
                  hidden={!w.show}
                  title={w.title}
                  headerCellOptions={w.headerCellOptions}
                  cellOptions={w.headerCellOptions}
                />
              );
            })}
          {/* {columns.map((w)=>(
    <ExcelExportColumn field={w.field}  title={w.title} />

  ))} */}
          {/* <ExcelExportColumn field="extrnlAcctId" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Shares" />
  <ExcelExportColumn field="cost" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Cost($)" />
  <ExcelExportColumn field="market" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Market($)" />
  <ExcelExportColumn field="unrGainLoss" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Unr Gain Loss($)" />
  <ExcelExportColumn field="estAnnInc" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Est Ann Income($)" />
  <ExcelExportColumn field="yield" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Yield(%)" /> */}
        </ExcelExportColumnGroup>
      </ExcelExport>
      <Grid
        //id='tileGrid'
        style={{ height: "450px" }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={columnReorder}
        //pageable={true}
        //expandField="expanded"
        //onExpandChange={expandChange}
        cellRender={cellRender}
        sortable={true}
        resizable={false}
        pageable={true}
        reorderable={true}
        scrollable="scrollable"
        //pageSize={10}
      >
        <GridToolbar>
          {/* <Button  sx={{ textTransform: 'none' }} variant="outlined" disabled>Add+</Button> */}
          <button
            className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
            onClick={excelExport}
          >
            <FaFileExcel></FaFileExcel>&nbsp; export
          </button>

          <button
            className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
            onClick={pdfExport}
          >
            <FaFilePdf></FaFilePdf>&nbsp; pdf
          </button>
        </GridToolbar>
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                //  locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                //cell={NumberCell}
                //columnMenu={column.columnMenu}
                // columnMenu={(props) => (
                //   <CustomColumnMenuNoGrp
                //     {...props}
                //     columns={stateColumns}
                //     onColumnsSubmit={onColumnsSubmit}
                //   />
                // )}
                columnMenu={
                  column.field === "comments"
                    ? ""
                    : (props) => (
                        <>
                          {column.filter === "text" ? (
                            <CustomColumnMenuNoGrpChkBox
                              {...props}
                              columns={stateColumns}
                              data={data}
                              onColumnsSubmit={onColumnsSubmit}
                            />
                          ) : column.filter === "numeric" ? (
                            <CustomColumnMenuNoGrp
                              {...props}
                              columns={stateColumns}
                              onColumnsSubmit={onColumnsSubmit}
                            />
                          ) : (
                            <CustomColumnMenuNoGrpChkBox
                              {...props}
                              columns={stateColumns}
                              data={data}
                              onColumnsSubmit={onColumnsSubmit}
                            />
                          )}
                        </>
                      )
                }
              />
            )
        )}
      </Grid>
      <style>
        {`
    
    .k-grid .k-table-row.k-table-alt-row {
        background: rgba(0, 0, 0, 0.17);
    }
    
    .k-master-row.k-table-alt-row .k-grid-content-sticky {
        background: rgba(0, 0, 0, 0.15);
    }
    `}
      </style>
      {themes === "dark" ? (
        <style>
          {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </div>
    // </ResponsiveContainer>
  );
};
export default WFGrid;