import React from "react";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Tabs } from "antd";
import Select from "@mui/material/Select";
import axios from "axios";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { ResponsiveContainer } from "recharts";
import DisbursementGrid from "./disbursementGrid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TransferGrid from "./transferGrid";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WFGrid from "./workflowGrid";
//import "@progress/kendo-theme-bootstrap/dist/all.css";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu"; //

import { groupBy } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
//import { ColumnMenu } from "./columnMenu";
import { orderBy } from "@progress/kendo-data-query";
import { BsArrowRight } from "react-icons/bs";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from "linq";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartSeriesLabels,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartArea,
} from "@progress/kendo-react-charts";
import { useState, useRef, useEffect } from "react";
import DepositGrid from "./depositGrid";
import MemoTickler from "./memoTickler";
import NetOverdraftChart from "./netOverdraftChart";
import NetoverflowGrid from "./netoverflowGrid";
import { DatePicker } from "@progress/kendo-react-all";
import { weekInYear, addDays } from "@progress/kendo-date-math";
import MemoTicklerData from "./memoTicklerdata";
import Loading from "./loading";
import { FaSync, FaAngleLeft } from "react-icons/fa";
import { process } from "@progress/kendo-data-query";
import AcctPerfHistory from "./acctPerfHistory";
import NetOverdraftRpt from "./netOverdraftRpt";
import { clear } from "@testing-library/user-event/dist/clear";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";

const DashboardTab = ({ dashboardData }) => {
  const [transactionData, settransactionData] = useState(dashboardData);
  //  if (localStorage.getItem("ADate") != "")
  //  {
  //   settransactionData (localStorage.getItem("ADate"))
  //  }
  //const [transactionData, settransactionData] = useState(dashboardData);

  // ALM - added handler to get data from memoTickler

  const [dataFromMemoTicker, setDataFromMemoTicker] = React.useState(
    dashboardData.lstMemoTickler.length
  );

  const handleDatafromMemoTicker = (memoCount) => {
    setDataFromMemoTicker(memoCount);
  };

  var tempToken = JSON.parse(localStorage.getItem("token"));
  localStorage.setItem("check", 1);
  const transTypelist = [
    { type: "Deposit", id: 1 },
    { type: "Transfer", id: 2 },
    { type: "Disbursement", id: 3 },
  ];
  const myRef = useRef(null);
  const TabPane = Tabs.TabPane;
  const myRefMemo = useRef(null);

  const [symbl, setsymbl] = React.useState("");
  const [astShrtNm, setastShrtNm] = React.useState("");
  const [total, settotal] = React.useState("rightHeader");
  const initialSort = [
    {
      field: "total",
      dir: "desc",
    },
  ];
  const themes = useSelector(selectTheme);
  const [sort, setSort] = React.useState(initialSort);
  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };

  const executeScroll = () => {
    myRef.current.scrollIntoView({ block: "end", behavior: "smooth" });

    localStorage.setItem("activeTabLower", 3);
    setActiveTab("3");
  };

  const executeScrollMemo = () => {
    // myRefMemo.current.scrollIntoView({ block: "end", behavior: "smooth" });
    myRef.current.scrollIntoView({ block: "end", behavior: "smooth" });

    localStorage.setItem("activeTabLower", 4);
    setActiveTab("4");
  };

  const [transType, settranstype] = React.useState(transTypelist[0]);

  const [loadingView, setLoading] = useState(false);
  // const result = groupBy(dashboardData.lstAssetData, [{ field: "mjrAstType" }]);
  const [chartCategory, setChartCategory] = React.useState("MinorAsset");
  var date = new Date(localStorage.getItem("processingDate"));
  const [asOfDt, setasOfDt] = React.useState(date);

  // const[symbl,setsymbl] = React.useState("");
  // const[astShrtNm,setastShrtNm ]= React.useState("");
  // const[total,settotal] = React.useState("");

  var newDate = addDays(date, 7);
  //newDate.setDate(newDate.getDate() + 7);

  const [toDt, settoDt] = React.useState(newDate);

  const [activeTab, setActiveTab] = React.useState(
    localStorage.getItem("activeTabLower") == null
      ? "1"
      : localStorage.getItem("activeTabLower")
  );
  //const [activeTab, setActiveTatotalb] = React.useState(1);
  const chartBootstrapV4Colors = [
    "#006fba",
    "#0C483E",
    "#00AB8D",
    "#65697D",
    "#D8DAE5",
    "#9E8AA3",
    "#D38BB2",
    "#7EF58B",
    "#E78B8B",
  ];

  // const result = groupBy(dashboardData.lstAssetData, [{ field: "mjrAstType" }]);

  var result = Enumerable.from(dashboardData.lstAssetData)
    .groupBy("$.mjrAstType", null, function (key, g) {
      var result = {
        mjrAstType: key,
        total: g.sum("$.mv"),
      };
      return result;
    })
    .where((w) => w.total > 0)
    .toArray();

  var resultMnr = Enumerable.from(dashboardData.lstAssetData)
    .groupBy("$.mnrAstType", null, function (key, g) {
      var result = {
        mnrAstType: key,
        total: g.sum("$.mv"),
      };
      return result;
    })
    .where((w) => w.total > 0)
    .orderByDescending((w) => w.total)
    .toArray();
  const [mnrAssetData, setMnrAssetData] = React.useState(resultMnr);
  const [mnrAssetName, setsetMnrAssetNm] = React.useState("");
  const [assetData, setAssetData] = React.useState([]);
  const [assetData1, setAssetData1] = React.useState([]);
  const [mnrAssetTopChecked, setMnrAssetTopChecked] = React.useState(true);
  const [mnrAssetTopVal, setMnrAssetTopVal] = React.useState("5");
  // var totalNetOverDraft = 0;

  // for (var i = 0; i < dashboardData.lstNetOverDraft.length; i++)
  //   totalNetOverDraft = totalNetOverDraft + dashboardData.lstNetOverDraft[i].overdraft;

  const [mjrAssetData, setMjrAssetData] = React.useState(result);
  const labelContent1 = (props) => {
    let formatedNumber = Number(props.percentage).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    // return `${props.category}  ${formatNumber(props.dataItem.mdlWegh, "##,#.00")}%`;

    return `${props.category} ${formatedNumber} `;
  };

  const plotareaclickMjrAll = (e) => {
    var mjrAsetTypeNm = e.category;

    var mnrAstdata = Enumerable.from(dashboardData.lstAssetData)
      .where((w) => w.mjrAstType === mjrAsetTypeNm)
      .toArray();
    setMnrAssetData(mnrAstdata);
    setChartCategory("MinorAsset");
  };

  const plotareaclickMinorAll = (e) => {
    var mnrAsetTypeNm = e.category;
    setsetMnrAssetNm(mnrAsetTypeNm);
    localStorage.setItem("mnrAssetNm", mnrAsetTypeNm);

    var astdata = Enumerable.from(dashboardData.lstAssetData)
      .where((w) => w.mnrAstType === mnrAsetTypeNm)
      .groupBy("$.astShrtNm", null, function (key, g) {
        var result = {
          astShrtNm: key,
          symbl: g.toArray()[0].symbl,
          total: g.sum("$.mv"),
          mvPercent: g.sum("$.mvPercent"),
        };
        return result;
      })
      .where((w) => w.total > 0)
      .orderByDescending((w) => w.total)
      .toArray();

    //var astdata = Enumerable.from(dashboardData.lstAssetData).where(w => w.mnrAstType === mnrAsetTypeNm).orderByDescending(w=>w.mrktVlAmt).toArray();
    setAssetData(astdata);

    let aastdata = {
      astdata,
      filter: [],
    };
    //setAssetData(astdata)
    localStorage.setItem("ChkPopUp1", 1);
    localStorage.setItem("ChkPopUp2", 1);

    //setAssetData1(Newastdata);
    setChartCategory("Asset");
    setsymbl("");
    setastShrtNm("");
    settotal("rightHeader");
    setResultState(process(astdata, initialDataState));
  };
  const plotareaclickMinorAxisAll = (e) => {
    if (!e.axis.vertical) {
      var mnrAsetTypeNm = e.value;
      setsetMnrAssetNm(mnrAsetTypeNm);
      localStorage.setItem("mnrAssetNm", mnrAsetTypeNm);
      var astdata = Enumerable.from(dashboardData.lstAssetData)
        .where((w) => w.mnrAstType === mnrAsetTypeNm)
        .toArray();
      setAssetData(astdata);
      //setAssetData1(astdata);
      setChartCategory("Asset");
      //setResultState(process(astdata, initialDataState));
      setResultState(process(astdata, e.dataState));
    }
  };

  const plotareaclickAssetAll = (e) => {
    setChartCategory("MinorAsset");
    setMnrAssetData(resultMnr);
  };

  const initialDataState = {
    skip: 0,
    take: 100,
    sort: [
      {
        field: "total",
        dir: "desc",
      },
    ],
  };

  const [dataState, setDataState] = React.useState();
  const [resultState, setResultState] = React
    .useState
    // process(astroData, initialDataState)
    ();

  //setResultState(localStorage.getItem("Adata"))

  const onDataStateChange = React.useCallback((e) => {
    var symblInd, astShrtNmInd, totalInd;
    var mnrAssetNm = localStorage.getItem("mnrAssetNm");
    setDataState(e.dataState);
    var astdata = Enumerable.from(dashboardData.lstAssetData)
      .where((w) => w.mnrAstType === mnrAssetNm)
      .groupBy("$.astShrtNm", null, function (key, g) {
        var result = {
          astShrtNm: key,
          symbl: g.toArray()[0].symbl,
          total: g.sum("$.mv"),
          mvPercent: g.sum("$.mvPercent"),
        };
        return result;
      })
      .where((w) => w.total > 0)
      .orderByDescending((w) => w.total)
      .toArray();

    // <Column field="symbl" title="Symbol" width="auto"  headerClassName="" columnMenu={ColumnMenu}  />

    //                 <Column field="astShrtNm" title="Short Name" width="auto" headerClassName="" columnMenu={ColumnMenu}

    //                 />
    //                 <Column field="total" title="Market Value($)" cell={NumberCell} columnMenu={ColumnMenu}

    if (e.dataState.filter !== undefined) {
      if (e.dataState.filter.filters.length > 0) {
        symblInd = 0;
        astShrtNmInd = 0;
        totalInd = 0;

        // for (var i = 0; i < e.stateColumns.length; i++) {
        for (var i = 0; i < e.target.columns.length; i++) {
          for (var j = 0; j < e.dataState.filter.filters.length; j++) {
            // a= e.target.columns[i].field;
            if (
              e.target.columns[i].field ==
              e.dataState.filter.filters[j].filters[0].field
            ) {
              // a.headerClassName="active";
              //Grid.columns[i].headerClassName =="active";
              if (e.dataState.filter.filters[j].filters[0].field == "symbl") {
                setsymbl("active");
                symblInd = 1;
              }
              if (
                e.dataState.filter.filters[j].filters[0].field == "astShrtNm"
              ) {
                setastShrtNm("active");
                astShrtNmInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "total") {
                settotal("active rightHeader");
                totalInd = 1;
              }

              //dataState.columns[i].headerClassName = "";
              break;
            } else {
              if (symblInd != 1) {
                setsymbl("");
              }
              if (astShrtNmInd != 1) {
                setastShrtNm("");
              }
              if (totalInd != 1) {
                settotal("rightHeader");
              }

              //e.target.columns[i].headerClassName="";
              //Grid.columns ="active";
            }
          }
        }
      } else {
        for (var i = 0; i < e.target.columns.length; i++) {
          // e.target.columns[i].headerClassName="";
          setsymbl("");
          setastShrtNm("");
          settotal("rightHeader");
        }
      }
    }
    if (e.dataState.filter == undefined) {
      setsymbl("");
      setastShrtNm("");
      settotal("rightHeader");
    }

    setAssetData(astdata);
    //setAssetData(e.dataState);
    //setResultState(astdata);
    setResultState(process(astdata, e.dataState));
  }, []);

  const FormatLongNumber = ({ value }) => {
    if (value == 0) {
      return 0;
    } else {
      if (value <= 999 && value >= -999) {
        return value;
      }

      // thousands
      else if (value >= 1000 && value <= 999999) {
        return value / 1000 + "K";
      }

      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return value / 1000000 + "M";
      }

      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return value / 1000000000 + "B";
      } else if (value <= -1000 && value >= -999999) {
        return value / 1000 + "K";
      } else if (value <= -1000000 && value >= -999999999) {
        return value / 1000 + "M";
      } else if (value <= -1000000000 && value >= -999999999999) {
        return value / 1000 + "B";
      } else return value;
    }
  };
  //  const defaultTooltipRender = ({ point }) => `$${formatNumber(point.value, "##,#.00")}`;

  const handleMnrAssetTopChecked = (event) => {
    setMnrAssetTopChecked(event.currentTarget.checked);
  };

  const handleTopChange = (event) => {
    setMnrAssetTopVal(event.target.value);
  };
  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    const itemChildren = (
      <span
        style={
          {
            //color: themes==='dark'?"white":"black",
          }
        }
      >
        {li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      settranstype(transType);
    } else {
      settranstype(event.target.value);
    }

    myRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    setActiveTab("1");
  };
  // const refresh=()=>{
  //   setChartCategory("MajorAsset");
  // }

  //const defaultTooltipRender = (props) => `${props.value}`;

  // const defaultTooltipRender = (point) => {

  //
  //   if(point.point!==undefined)
  //   return point.point.value;

  // }
  if (
    localStorage.getItem("pConsId") == 0 &&
    localStorage.getItem("SelAcctId") == 0 &&
    localStorage.getItem("pActiveLTab") == 0
  ) {
    localStorage.setItem("activeTabLower", "1");
    localStorage.setItem("pActiveLTab", "1");
    setActiveTab("1");
  }
  const handleTabClick = (e) => {
    localStorage.setItem("activeTabLower", e);
    setActiveTab(e);
  };

  const labelContent = (e) => `${e.value.toFixed(2)}%`;
  function setDate(val) {
    if (val == null) {
      setasOfDt(asOfDt);
    } else {
      setasOfDt(val);
    }
  }
  function setPrcDt(val) {
    settoDt(val);
  }

  const transacData = async () => {
    let pMode = localStorage.getItem("pMode");
    let pConsId = localStorage.getItem("pConsId");
    let pAcctId = localStorage.getItem("pAcctId");
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId")); // 78034;
    let RoleTypeId = localStorage.getItem("UserRoleData"); //2;
    let Mode;
    let CnsldtnId = pConsId;
    let AcctId = pAcctId;
    let FrmDt = asOfDt;
    let ToDt = toDt;
    localStorage.setItem("SelAcctId", AcctId);
    if (pMode == "Account") Mode = 1;
    else Mode = 0;

    const postData = {
      UserId,
      RoleTypeId,
      CnsldtnId,
      AcctId,
      Mode,
      FrmDt,
      ToDt,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    await axios
      .post("/AdvSchdlTransferGetByDt", postData, config)
      .then((response) => {
        //setLoadingView(false);

        const rowData = response.data;
        console.log(rowData);

        //setRelationData(rowData.lstCons);

        // if (initialCall) {
        //     setSelRelationship(rowData.lstCons[0]);
        //     if(rowData.lstCons.length>0)
        //     setConsId(rowData.lstCons[0].cnsldtnId);
        //     else
        //     setConsId(0);
        //     var ConsAcct = Enumerable.from(rowData.lstConsolidationAcct).where(w => w.cnsldtnId === rowData.lstCons[0].cnsldtnId)
        //         .toArray()
        //     setAcctDataConsAll(ConsAcct);
        //     setAcctDataConsAllBasic(ConsAcct);
        //    // setInitialCall(false);
        // }
        // else {
        //setAcctDataConsAll(rowData.lstAcctAll);
        //setAcctDataConsAllBasic(rowData.lstAcctAll);
        // }

        settransactionData(rowData);
        //let procDt = new Date(rowData.procDate);
        //let pdt = (procDt.getMonth() + 1).toString().padStart(2, '0') + "/" + (procDt.getDate().toString().padStart(2, '0')) + "/" + (procDt.getFullYear());
        //localStorage.setItem('processingDate', pdt);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken(pMode, pConsId, pAcctId);
        }
        return error;
      });
  };

  const menuWithExcelCheckOnly = (props) => {
    const props1 = { ...props };
    if (localStorage.getItem("ChkPopUp1") == 1) {
      if (props.column.field == "symbl") {
        delete props1.filter;
        if (symbl == "") {
          localStorage.setItem("ChkPopUp1", 0);
        }
      }
    }

    if (localStorage.getItem("ChkPopUp2") == 1) {
      if (props.column.field == "astShrtNm") {
        delete props1.filter;
        if (astShrtNm == "") {
          localStorage.setItem("ChkPopUp2", 0);
        }
      }
    }

    return (
      <div>
        <ColumnMenuCheckboxFilter {...props1} data={assetData} />
      </div>
    );
  };

  const refreshToken = async (pMode, pConsId, pAcctId) => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        var Mode;
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));
        if (pMode == "Account") Mode = 1;
        else Mode = 0;
        transacData(Mode, pConsId, pAcctId);
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          //  setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  console.log(resultState);
  localStorage.setItem("Adate", resultState);

  // if (localStorage.getItem("Adate") != null)
  // {
  //   setResultState(localStorage.getItem("Adate"))
  // }

  return (
    <div>
      <div
        className="container-fluid"
        style={{ backgroundColor: themes === "dark" ? "black" : "white" }}
      >
        <div className="row ">
          <Card
            style={{
              borderRadius: "0px",
              alignContent: "center",
              justifyContent: "center",
            }}
            className="col"
          >
            <div className="kpicard">
              <div
                className="kpiheader"
                style={{ alignContent: "center", justifyContent: "center" }}
              >
                Total Cash
              </div>
              <hr className="mt-1 mb-0 pb-0"></hr>

              <div className="kpibody">
                $
                {dashboardData.availableCash === undefined
                  ? "0"
                  : formatNumber(dashboardData.availableCash, "##,#")}
              </div>
            </div>
          </Card>
          <Card
            style={{
              borderRadius: "0px",
              alignContent: "center",
              justifyContent: "center",
            }}
            className="col"
          >
            <div className="kpicard">
              <div
                className="kpiheader"
                style={{ alignContent: "center", justifyContent: "center" }}
              >
                Total Market Value
              </div>
              <hr className="mt-1 mb-0 pb-0"></hr>

              <div className="kpibody">
                $
                {(dashboardData.aum === undefined
                  ? "0"
                  : dashboardData.aum
                ).toLocaleString()}
              </div>
            </div>
          </Card>
          <Card
            style={{
              borderRadius: "0px",
              alignContent: "center",
              justifyContent: "center",
            }}
            className="col"
          >
            <div className="kpicard">
              <div
                className="kpiheader"
                style={{ alignContent: "center", justifyContent: "center" }}
              >
                YTD Fees
              </div>
              <hr className="mt-1 mb-0 pb-0"></hr>

              <div className="kpibody">
                $
                {(dashboardData.ytfFees === undefined
                  ? "0"
                  : dashboardData.ytfFees
                ).toLocaleString()}
              </div>
            </div>
          </Card>
          <Card
            style={{
              borderRadius: "0px",
              alignContent: "center",
              justifyContent: "center",
            }}
            className="col"
          >
            <div className="kpicard">
              <div
                className="kpiheader"
                style={{ alignContent: "center", justifyContent: "center" }}
              >
                Workflows{" "}
                <a onClick={executeScroll}>
                  <BsArrowRight></BsArrowRight>
                </a>{" "}
              </div>
              <hr className="mt-1 mb-0 pb-0"></hr>

              <div className="kpibody">{dashboardData.lstWorkflow.length}</div>
            </div>
          </Card>
          <Card
            style={{
              borderRadius: "0px",
              alignContent: "center",
              justifyContent: "center",
            }}
            className="col"
          >
            <div className="kpicard">
              <div
                className="kpiheader"
                style={{ alignContent: "center", justifyContent: "center" }}
              >
                Ticklers{" "}
                <a onClick={executeScrollMemo}>
                  <BsArrowRight></BsArrowRight>
                </a>
              </div>
              <hr className="mt-1 mb-0 pb-0"></hr>
              <div className="kpibody">{dataFromMemoTicker}</div>
            </div>
          </Card>
        </div>

        <div className="row card-group my-1 d-flex">
          <Card className="col ">
            <div>
              <div className="kpiheader">Portfolio By Asset Class</div>
              <div className="card-body">
                <ResponsiveContainer>
                  <Chart
                    seriesColors={chartBootstrapV4Colors}
                    style={{ maxHeight: "400px" }}
                  >
                    <ChartArea
                      background={themes === "dark" ? "#292929" : "white"}
                      margin={30}
                    />
                    <ChartLegend
                      position="bottom"
                      labels={{
                        color: themes === "dark" ? "white" : "black",
                      }}
                      inactiveItems={{
                        labels: {
                          color:
                            themes === "dark"
                              ? "rgba(255, 255, 255, 0.43)"
                              : "rgba(0, 0, 0, 0.43)",
                        },
                      }}
                    />
                    <ChartValueAxis>
                      <ChartValueAxisItem
                        // title={{
                        //     text: "Percentage",
                        // }}
                        min={0}
                        labels={{
                          visible: true,

                          // rotation: 85,
                          format: "d",
                          //content: FormatLongNumber
                        }}
                      />
                    </ChartValueAxis>
                    <ChartTooltip format="c2" />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="donut"
                        // overlay={{
                        //   gradient: "sharpBevel",
                        // }}
                        data={mjrAssetData}
                        field="total"
                        categoryField="mjrAstType"
                        //colorField='chrtColorId'
                        autoFit={true}
                        labels={{
                          background: "none",
                          visible: true,
                          position: "outsideEnd",
                          format: "d",
                          backgroundColor: "rgba(0,0,0,0)",
                          color: themes === "dark" ? "white" : "black",
                          content: labelContent1,
                        }}
                      />
                    </ChartSeries>
                  </Chart>

                  {/* {chartCategory === "MajorAsset" ?
          <Chart onSeriesClick={plotareaclickMjrAll}
            seriesColors={chartBootstrapV4Colors}
            style={{ height: "400px" }}>
            <ChartTitle text="Major Asset Chart" />
            <ChartLegend position="bottom" />
            <ChartValueAxis>
              <ChartValueAxisItem
                // title={{
                //     text: "Percentage",
                // }}
                min={0}
                labels={{
                  visible: true,

                  // rotation: 85,
                  format: "d",
                  //content: FormatLongNumber

                }}
              />
            </ChartValueAxis>
           
            <ChartSeries>
              <ChartSeriesItem
                type="donut"
                overlay={{
                    gradient: "sharpBevel",
                  }}
                data={mjrAssetData}
                field="total"
                categoryField="mjrAstType"
                //colorField='chrtColorId'
                autoFit={true}
                labels={{
                  visible: true,
                  position: "outsideEnd",
                  format: "d",
                  content: labelContent1,
                }}
              />
            </ChartSeries>
          </Chart>
          : chartCategory === "MinorAsset" ?
            <Chart onSeriesClick={plotareaclickMinorAll}
              seriesColors={chartBootstrapV4Colors}
              style={{ height: "400px" }}>
              <ChartLegend position="bottom" />

              <ChartValueAxis>
                <ChartValueAxisItem
                  // title={{
                  //     text: "Percentage",
                  // }}
                  min={0}
                  labels={{
                    visible: true,

                    // rotation: 85,
                    //format: "d",
                    content: FormatLongNumber

                  }}
                />
              </ChartValueAxis>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    visible: true,
                    rotation: 60,
                    format: "d",
                  }}
                //  categories={categoryAxis} 
                />
              </ChartCategoryAxis>
              
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  data={mnrAssetData}
                  field="mv"
                  categoryField="mnrAstType"

                  labels={{
                    visible: false,
                    content: labelContent1,
                  }}
                  overlay={{
                    gradient: "sharpBevel",
                  }}
                />
              </ChartSeries>
            </Chart>
            : <>
              <Chart onSeriesClick={plotareaclickAssetAll} pannable={{
                lock: "y",
              }}
                zoomable={{
                  mousewheel: {
                    lock: "y",
                  },
                }}
                seriesColors={chartBootstrapV4Colors}
                style={{ height: "400px" }}>
                <ChartLegend position="bottom" />

                <ChartValueAxis>
                  <ChartValueAxisItem
                    // title={{
                    //     text: "Percentage",
                    // }}
                    min={0}
                    labels={{
                      visible: true,

                      // rotation: 85,
                      //format: "d",
                      content: FormatLongNumber

                    }}
                  />
                </ChartValueAxis>
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{
                      visible: true,
                      rotation: 60,
                      format: "d",
                    }}
                  //  categories={categoryAxis} 
                  />
                </ChartCategoryAxis>
               
                <ChartSeries>
                  <ChartSeriesItem
                    overlay={{
                      gradient: "sharpBevel",
                    }}
                    type="column"
                    data={mnrAssetData}
                    field="mrktVlAmt"
                    categoryField="astShrtNm"

                    labels={{
                      visible: false,
                      content: labelContent1,
                    }}
                  />
                </ChartSeries>
              </Chart>
            </>

        }  */}
                </ResponsiveContainer>
              </div>
            </div>
          </Card>
          <Card className="col">
            <div>
              <div className="card-header  kpiheader2">
                {" "}
                {chartCategory === "MinorAsset" ? (
                  <div className="row">
                    {" "}
                    <div className="col-4">Portfolio By Minor Asset </div>{" "}
                    <div className="col-6">
                      {" "}
                      <input
                        className="col-end"
                        type="checkbox"
                        onClick={handleMnrAssetTopChecked}
                        checked={mnrAssetTopChecked}
                      ></input>{" "}
                      Top &nbsp;
                      <FormControl style={{ width: 70, height: 25 }}>
                        <Select
                          id="demo-simple-select"
                          value={mnrAssetTopVal}
                          style={{ width: 70, height: 25, fontSize: 15 }}
                          onChange={handleTopChange}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={15}>15</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                        </Select>
                      </FormControl>
                      &nbsp; Minor Asset(s){" "}
                    </div>
                  </div>
                ) : (
                  <> Portfolio By Asset(s) under {mnrAssetName}</>
                )}
              </div>

              <div className="card-body">
                <ResponsiveContainer>
                  {chartCategory === "MinorAsset" ? (
                    <Chart
                      onSeriesClick={plotareaclickMinorAll}
                      //onAxisLabelClick={plotareaclickMinorAxisAll}
                      //zoomable={false}
                      pannable={{
                        lock: "y",
                      }}
                      zoomable={{
                        mousewheel: {
                          lock: "y",
                        },
                      }}
                      seriesColors={chartBootstrapV4Colors}
                      style={{ height: "400px" }}
                    >
                      <ChartArea
                        background={themes === "dark" ? "#292929" : "white"}
                        margin={30}
                      />
                      <ChartLegend
                        position="bottom"
                        labels={{
                          color: themes === "dark" ? "white" : "black",
                        }}
                      />

                      <ChartValueAxis>
                        <ChartValueAxisItem
                          // title={{
                          //     text: "Percentage",
                          // }}
                          min={0}
                          minorGridLines={{
                            color: themes === "dark" ? "#e0e0e0" : "#7a7a7a",
                          }}
                          majorGridLines={{
                            color: themes === "dark" ? "#e0e0e0" : "#7a7a7a",
                          }}
                          labels={{
                            visible: true,
                            color: themes === "dark" ? "white" : "black",
                            // rotation: 85,
                            //format: "d",
                            content: FormatLongNumber,
                          }}
                        />
                      </ChartValueAxis>
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                          labels={{
                            visible: true,
                            rotation: 70,
                            format: "d",
                            color: themes === "dark" ? "white" : "black",
                          }}
                          //  categories={categoryAxis}
                        />
                      </ChartCategoryAxis>
                      <ChartTooltip format="c2" />
                      <ChartSeries>
                        <ChartSeriesItem
                          type="column"
                          data={
                            mnrAssetTopChecked
                              ? mnrAssetData.slice(0, mnrAssetTopVal)
                              : mnrAssetData
                          }
                          field="total"
                          categoryField="mnrAstType"
                          labels={{
                            visible: false,
                            content: labelContent1,
                          }}
                          // overlay={{
                          //   gradient: "sharpBevel",
                          // }}
                        />
                      </ChartSeries>
                    </Chart>
                  ) : (
                    <>
                      <Grid
                        style={{ height: "400px" }}
                        // data={orderBy(mnrAssetData, sort)}
                        data={{ data: resultState.data }}
                        // filterable={true}
                        resizable={true}
                        reorderable={true}
                        sortable={true}
                        skip={0}
                        take={100}
                        // sort={sort}
                        // onSortChange={(e) => {
                        //   setSort(e.sort);
                        // }}
                        // pageable={{
                        //   pageSizes: true,
                        //    buttonCount: 4
                        // }}
                        pageable={true}
                        // pageSize={100}
                        total={resultState.total}
                        onDataStateChange={onDataStateChange}
                        {...dataState}
                      >
                        <GridToolbar>
                          <Button
                            sx={{ textTransform: "none" }}
                            color={themes === "dark" ? "primary" : "info"}
                            variant="outlined"
                            onClick={plotareaclickAssetAll}
                          >
                            <FaAngleLeft></FaAngleLeft>
                          </Button>
                        </GridToolbar>
                        <Column
                          field="symbl"
                          title="Symbol"
                          width="auto"
                          headerClassName={symbl}
                          columnMenu={menuWithExcelCheckOnly}
                        />

                        <Column
                          field="astShrtNm"
                          title="Short Name"
                          width="auto"
                          headerClassName={astShrtNm}
                          columnMenu={menuWithExcelCheckOnly}
                        />
                        <Column
                          field="total"
                          title="Market Value($)"
                          cell={NumberCell}
                          columnMenu={ColumnMenu}
                          width="auto"
                          format="{0:n2}"
                          filter="numeric"
                          headerClassName={total}
                        />
                        {/* <Column field="mvPercent" title="Market Value(%)" headerClassName='rightHeader' cell={NumberCell}
                
                  format="{0:n2}" width="auto" filter={"numeric"} columnMenu={ColumnMenu} /> */}
                      </Grid>

                      {/* <Chart onSeriesClick={plotareaclickAssetAll}
                      onAxisLabelClick={plotareaclickAssetAll}
                      pannable={{
                        lock: "y",
                      }}
                      zoomable={{
                        mousewheel: {
                          lock: "y",
                        },
                      }}
                      seriesColors={chartBootstrapV4Colors}
                      style={{ height: "400px" }}>
                      <ChartLegend position="bottom" />

                      <ChartValueAxis>
                        <ChartValueAxisItem
                          // title={{
                          //     text: "Percentage",
                          // }}
                          min={0}
                          labels={{
                            visible: true,

                            // rotation: 85,
                            //format: "d",
                            content: FormatLongNumber

                          }}
                        />
                      </ChartValueAxis>
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                          labels={{
                            visible: true,
                            rotation: 60,
                            format: "d",
                          }}
                        //  categories={categoryAxis} 
                        />
                      </ChartCategoryAxis>
                      <ChartTooltip format='c2' />
                      <ChartSeries>
                        <ChartSeriesItem
                          // overlay={{
                          //   gradient: "sharpBevel",
                          // }}
                          type="column"
                          data={mnrAssetData}
                          field="mrktVlAmt"
                          categoryField="astShrtNm"

                          labels={{
                            visible: false,
                            content: labelContent1,
                          }}
                        />
                      </ChartSeries>
                    </Chart> */}
                    </>
                  )}
                </ResponsiveContainer>
              </div>
            </div>
          </Card>
        </div>

        <Card>
          <div className="row mt-2">
            <div className="pt-3  " ref={myRef}>
              <Tabs activeKey={activeTab} type="card" onChange={handleTabClick}>
                <TabPane tab="Cash Activity" key="1">
                  <ComboBox
                    style={{
                      width: "auto",
                      color: themes === "dark" ? "white" : "black",
                      backgroundColor: themes === "dark" ? "#292929" : "white",
                    }}
                    itemRender={itemRender}
                    data={transTypelist}
                    textField="type"
                    dataItemKey="id"
                    filterable={true}
                    value={transType}
                    label=""
                    onChange={handleChange}
                    clearButton={false}
                    // onFilterChange={filterChange}
                  />
                  <hr></hr>
                  {transType.id === 1 ? (
                    <DepositGrid dashboardData={transactionData}></DepositGrid>
                  ) : transType.id === 2 ? (
                    <TransferGrid dashboardData={transactionData} />
                  ) : (
                    <DisbursementGrid
                      dashboardData={transactionData}
                    ></DisbursementGrid>
                  )}
                </TabPane>
                <TabPane tab="Net Overdraft" key="2">
                  {" "}
                  <NetOverdraftRpt></NetOverdraftRpt>
                </TabPane>
                <TabPane tab="Workflow" key="3">
                  {" "}
                  <WFGrid dashboardData={dashboardData}></WFGrid>
                </TabPane>
                <TabPane tab="Memo Tickler" key="4">
                  <MemoTickler
                    dashboardData={dashboardData}
                    DatafromMemoTicker={handleDatafromMemoTicker}
                  ></MemoTickler>
                </TabPane>
                <TabPane tab="Historical Market Value" key="5">
                  <AcctPerfHistory
                    dashboardData={dashboardData}
                  ></AcctPerfHistory>
                </TabPane>
              </Tabs>

              {/* <div className="card-header kpiheader bg-white">
                    <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="first-tab" data-bs-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Scheduled Transactions</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="second-tab" data-bs-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false">Net Overflow</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="third-tab" data-bs-toggle="tab" href="#third" role="tab" aria-controls="third" aria-selected="false">Workflow</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="fourth-tab" data-bs-toggle="tab" href="#fourth" role="tab" aria-controls="fourth" aria-selected="false"> Memo Tickler</a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body dbtab">
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">

                    
             
              
           
              {loadingView?<Loading></Loading>: transType.id === 1 ?
              <DepositGrid dashboardData={transactionData}></DepositGrid>
              : transType.id === 2 ?
                <TransferGrid dashboardData={transactionData} />
                :
                <DisbursementGrid dashboardData={transactionData}></DisbursementGrid>
            }

                      </div>
                      <div className="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">

                      <NetoverflowGrid dashboardData={dashboardData}></NetoverflowGrid>
                      </div>
                      <div className="tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">

                      <WFGrid dashboardData={dashboardData}></WFGrid>

                      </div>
                      <div className="tab-pane fade" id="fourth" role="tabpanel" aria-labelledby="fourth-tab"><MemoTickler dashboardData={dashboardData}></MemoTickler></div>
                    </div>
                  </div> */}
            </div>
          </div>
        </Card>
        {themes === "dark" ? (
          <style>
            {`.k-list-md .k-list-item, .k-list-md .k-list-optionlabel {
    
    background-color: #292929;

    }
    .k-pager-numbers-wrap{
  color: rgb(135, 137, 252);
}
.k-pager-numbers .k-selected {
 
  color: rgb(236, 238, 255);
  
}
    .k-list-item{
    color: white
    }
    .k-list-item:hover{
    color: #008cff
    }
    .k-list-item.k-selected {
  color: #fff !important;
  background-color: #008deb !importantimpo;
}
  .k-grid-md td, .k-grid-md .k-table-td {
    
    color: white !important;
}
    .k-grid .k-table-row.k-table-alt-row {
        background: rgba(0, 0, 0, 0.17);
    }
    
    .k-master-row.k-table-alt-row .k-grid-content-sticky {
        background: rgba(0, 0, 0, 0.15);
    }
    `}
          </style>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DashboardTab;
