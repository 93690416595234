import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { useState, useEffect, useRef } from "react";
import { ResponsiveContainer } from "recharts";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { process } from "@progress/kendo-data-query";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { FaFileExcel } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { Viewer } from "@grapecity/activereports-react";
import { FaFilePdf } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import React from "react";
const NetoverflowGrid = ({ dashboardData }) => {
  const themes = useSelector(selectTheme);
  const _export = useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  

  const [data, setData] = useState(dashboardData.lstNetOverDraft);
  const [show, setShow] = useState(false);
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const CustomGroupHeader = (props) => {
    return `${props.value}`;
  };

  var excelTitle = "";
  if (
    localStorage.getItem("SelAcctId") == 0 &&
    localStorage.getItem("pConsName") != 0 &&
    localStorage.getItem("pConsName") != null
  ) {
    excelTitle = "Consolidation:" + localStorage.getItem("pConsName");
  }
  if (localStorage.getItem("SelAcctId") != 0) {
    excelTitle = "Account Number:" + localStorage.getItem("SelAcctNm");
  }

  let defaultColumns = [
    {
      title: "Account#",
      field: "accountName",
      minWidth: "350",
      show: true,
      filter: "text",
      locked: false,
      orderIndex: -1,
      //columnMenu:menuWithExcelCheck
    },
    {
      title: "Principal",
      field: "p1CashBlncAmt",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      orderIndex: 0,
      //columnMenu:menuWithoutExcelCheck
    },
    {
      title: "Income",
      field: "p2CashBlncAmt",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 1,
      //columnMenu:menuWithoutExcelCheck
    },
    {
      title: "Invested Income",
      field: "p3CashBlncAmt",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 2,
      //columnMenu:menuWithoutExcelCheck
    },
    {
      title: "Book Value",
      field: "bkVlAmt",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 3,
      //columnMenu:menuWithoutExcelCheck
    },

    {
      title: "Overdraft",
      field: "overdraft",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 4,
      //columnMenu:menuWithoutExcelCheck
    },
  ];

  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
  });

  const viewerRef = React.useRef();

  const onClick = () => {
    setShow(!show);
  };

  async function loadReport() {
    // load report definition from the file


    const reportResponse = await fetch("NetOverdraft.rdlx-json");


    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    console.log("JSON", data);
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(data);

      let parameters = [
        {
          // Name: "pConsName",
          // Value: [
          //   localStorage.getItem("SelAcctNm") == ""
          //     ? 0
          //     : localStorage.getItem("SelAcctNm"),
          // ],
          Name: "pConsName",
          Value: [
            localStorage.getItem("pConsName") == "" ||
            localStorage.getItem("pConsName") == null
              ? 0
              : localStorage.getItem("pConsName"),
          ],
        },
        {
          Name: "pAccName",
          Value: [
            localStorage.getItem("SelAcctNm") == "" ||
            localStorage.getItem("SelAcctNm") == null
              ? 0
              : localStorage.getItem("SelAcctNm"),
          ],
          // Name: "pConsName",
          // Value: [
          //   localStorage.getItem("pConsName") == ""
          //     ? 0
          //     : localStorage.getItem("pConsName"),
          // ],
        },
        {
          Name: "pShow",
          Value: [
            localStorage.getItem("SelAcctId") !== "0" &&
            localStorage.getItem("SelAcctId") !== undefined &&
            localStorage.getItem("SelAcctId") !== null
              ? "1"
              : localStorage.getItem("pConsId") !== "0" &&
                localStorage.getItem("pConsId") !== undefined &&
                localStorage.getItem("pConsId") !== null
              ? "2"
              : "0",
  
            // localStorage.getItem("pConsId") == "0"
            //   ? 0
            //   : localStorage.getItem("SelAcctNm"),
          ],
          // Name: "pConsName",
          // Value: [
          //   localStorage.getItem("pConsName") == ""
          //     ? 0
          //     : localStorage.getItem("pConsName"),
          // ],
        },
      ];
  
      viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const pdfExport = () => {
    setShow(true);
    openReport();
  };

  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(defaultColumns);
  const [columnLatest, setColumnLatest] = React.useState(null);
  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }

    //newColumnsState[6].footerCell = totalSum;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    var tempcolumn = [];

    var j = 0;
    for (var i = 0; i < newColumnsState.length; i++) {
      tempcolumn[j] = newColumnsState[i];

      j++;
    }

    return tempcolumn;
    const [, ...restnew] = newColumnsState;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    if (
      localStorage.getItem("SelAcctId") > 0 &&
      newColumnsState[0].field === "accountName"
    ) {
      return restnew;
    } else {
      return newColumnsState;
    }

    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithExcelCheck;
    // newColumnsState[3].columnMenu = menuWithExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[12].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[13].columnMenu = menuWithExcelCheck;
    // newColumnsState[14].columnMenu = menuWithExcelCheck;
    // newColumnsState[15].columnMenu = menuWithExcelCheck;
    // newColumnsState[16].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[17].columnMenu = menuWithExcelCheck;
    // newColumnsState[18].columnMenu = menuWithExcelCheck;
    // newColumnsState[19].columnMenu = menuWithExcelCheck;
    // newColumnsState[20].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[21].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[22].columnMenu = menuWithExcelCheck;
    // newColumnsState[23].columnMenu = menuWithExcelCheck;
    // newColumnsState[24].columnMenu = menuWithExcelCheck;
  };
  const columnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
    setColumnLatest(props.target._columns);
  };
  const dataStateChange = (event) => {
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      const columns = defaultColumns;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === 'groupFooter') {

    //   if (cellProps.field === 'totMarket') {
    //     return (
    //       <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //         {cellProps.dataItem.aggregates.totMarket.sum}
    //       </td>
    //     );
    //   }

    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "overdraft") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p1CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p2CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p3CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "bkVlAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };
  return (
    <div>
       <Modal
        show={show}
        onHide={onClick}
        fullscreen={true}
        size="xl"
        style={{ zIndex: 100000 }}
      >
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={onClick}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <ExcelExport data={data} ref={_export} fileName={"NetOverdraft.xlsx"}>
        <ExcelExportColumnGroup
          title={
            excelTitle +
            "  Processing Date: " +
            localStorage.getItem("processingDate")
          }
          //title={excelTitle}
          headerCellOptions={{
            textAlign: "left",
          }}
        >
          {stateColumns
            .sort(function (a, b) {
              return a.orderIndex - b.orderIndex;
            })
            .map((w) => {
              return (
                <ExcelExportColumn
                  field={w.field}
                  hidden={!w.show}
                  title={w.title}
                  headerCellOptions={w.headerCellOptions}
                  cellOptions={w.cellOptions}
                />
              );
            })}
          {/* <ExcelExportColumn
    field="tranTypNm"
    hidden={true}
    groupHeader={CustomGroupHeader}
  />

  <ExcelExportColumn field="accountName" title="Account#" />
 
  <ExcelExportColumn field="p1CashBlncAmt" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Principal" />
  <ExcelExportColumn field="p2CashBlncAmt" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Income" />
  <ExcelExportColumn field="p3CashBlncAmt" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Invested Income" />
  <ExcelExportColumn field="bkVlAmt" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Book Value" />
  <ExcelExportColumn field="overdraft" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Overdraft" />
   */}
        </ExcelExportColumnGroup>
      </ExcelExport>
      <Grid
        style={{ height: "450px" }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        //scrollable='virtual'
        expandField="expanded"
        onExpandChange={expandChange}
        cellRender={cellRender}
        onColumnReorder={columnReorder}
        sortable={true}
        resizable={false}
        pageable={true}
        reorderable={true}

        //pageSize={10}
      >
        <GridToolbar>
          <button
            className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
            onClick={excelExport}
          >
            <FaFileExcel></FaFileExcel>&nbsp; export
          </button>
          <button
            className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
            onClick={pdfExport}
          >
            <FaFilePdf></FaFilePdf>&nbsp; pdf
          </button>
        </GridToolbar>
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                //  locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                //cell={NumberCell}
                //columnMenu={column.columnMenu}
                // columnMenu={(props) => (
                //   <CustomColumnMenuNoGrp
                //     {...props}
                //     columns={stateColumns}
                //     onColumnsSubmit={onColumnsSubmit}
                //   />
                // )}
                columnMenu={(props) => (
                  <>
                    {column.filter === "text" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : column.filter === "numeric" ? (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}
              />
            )
        )}
      </Grid>
      {themes === "dark" ? (
        <style>
          {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </div>
  );
};
export default NetoverflowGrid;
