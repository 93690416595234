import React from "react";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import ConsolidationGrid from "./consolidationGrid";
import Loading from "./loading";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  FaFileExcel,
  FaPlusSquare,
  FaMinusSquare,
  FaSave,
} from "react-icons/fa";
import { blue, grey } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from "@telerik/kendo-intl";

import { Modal } from "antd";
import { Button } from "@progress/kendo-react-buttons";

const ConsolidationRpt = () => {
  //const [frmDate, setfrmDate] = React.useState(date);

  var disb =
    localStorage.getItem("SelAcctId") == 0
      ? localStorage.getItem("pRltncd") == null ||
        localStorage.getItem("pRltncd") == undefined ||
        localStorage.getItem("pRltncd") == ""
        ? localStorage.getItem("pConsId") === "0"
          ? false
          : true
        : false
      : false;
  const [consolidationData, setConsolidationData] = useState([]);
  const [showall, setShowall] = useState(
    localStorage.getItem("pConsId") == "0" ? true : false
  );
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  var date = new Date(localStorage.getItem("processingDate"));
  const [asOfDt, setasOfDt] = React.useState(date);
  const [priceDt, setpriceDt] = React.useState(date);
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  const [loading, setLoading] = useState(true);
  const [isResponse, setIsResponse] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [triggerColSave, setTriggerColSave] = useState(0);
  const [triggerColapse, setTriggerColapse] = useState(0);
  const [triggerExpand, setTriggerExpand] = useState(0);
  const [expandedState, setExpandedState] = React.useState(true);
  const [triggerExport, setTriggerExport] = useState(0);
  const [effectivecDtInd, seteffectiveDtInd] = useState(true);
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked ? true : false);
  };
  const themes = useSelector(selectTheme);
  function setDate(val) {
    if (val == null) {
      setasOfDt(asOfDt);
    } else {
      setasOfDt(val);
    }
  }
  function setPrcDt(val) {
    if (val == null) {
      setpriceDt(priceDt);
    } else {
      setpriceDt(val);
    }
  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)

      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetconsolidationData();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };

  const BlueSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: blue[600],
      "&:hover": {
        backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: blue[600],
    },

    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: showall
        ? localStorage.getItem("pConsId") == 0
          ? blue[300]
          : blue[600]
        : grey[300],
    },
  }));
  const handleTriggerColapse = (event) => {
    setTriggerColapse((triggerColapse) => triggerColapse + 1);
    setExpandedState(false);
  };

  //new
  const [DialogMsg, setDialogMsg] = useState("");
  const [visible, setVisible] = React.useState(false);
  const toggleDialog = () => {
    setVisible(!visible);
  };

  const formSubmit = (event) => {
    const validationRules = [
      {
        condition: asOfDt > maxDate,
        message: "asOfDt cannot be exceed to the Processing Date",
      },
      {
        condition: asOfDt < minDate,
        message: "asOfDt cannot be less than 5 years to the Processing Date",
      },
      {
        condition: priceDt > maxDate,
        message: "priceDt cannot be exceed to the Processing Date",
      },
      {
        condition: priceDt < minDate,
        message: "priceDt cannot be less than 5 years to the Processing Date",
      },
    ];

    const messages = validationRules
      .filter((rule) => rule.condition)
      .map((rule) => rule.message);

    if (messages.length > 0) {
      setDialogMsg(messages.join("\n"));
      toggleDialog();
      return;
    }

    setTriggerColSave(0);
    GetconsolidationData();
    setExpandedState(true);
  };
  const handleTriggerExpand = (event) => {
    setTriggerExpand((triggerExpand) => triggerExpand + 1);
    setExpandedState(true);
  };
  const handleExcelExport = (event) => {
    setTriggerExport((triggerExport) => triggerExport + 1);
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 36;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        // console.log('save in db');
        // console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };
  const handleTriggerColSave = (event) => {
    setTriggerColSave((triggerColSave) => triggerColSave + 1);
  };
  const handlechangeCheckbox = (e) => {
    setShowall(e.target.checked);

    GetconsolidationData(e.target.checked);
    setTriggerColSave(0);
  };
  const GetconsolidationData = async (showall) => {
    setLoading(true);
    let EffectiveDtInd = effectivecDtInd;
    console.log("GetconsolidationData here");
    let UserId = JSON.parse(localStorage.getItem("userId"));
    //let UserId = 1744;
    let lAcctId =
      localStorage.getItem("SelAcctId") == null
        ? 0
        : localStorage.getItem("SelAcctId");
    let AcctId = showall === true ? "0" : lAcctId;
    let AsofDt = formatDate(asOfDt, "MM/dd/yyyy");
    let PriceDt = priceDt === null ? "" : formatDate(priceDt, "MM/dd/yyyy");
    //let startDate = frmDate;
    //let endDate = toDate;
    let token = tempToken;
    let CnsldtnId = showall === true ? "0" : localStorage.getItem("pConsId");
    //let CnsldtnTypId = 14;
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    const postData = {
      UserId,
      CnsldtnId,
      AcctId,
      RltnshpCd,
      EffectiveDtInd,
      AsofDt,
      PriceDt,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AdvGetConsolView", postData, config)
      .then((response) => {
        setLoading(false);
        console.log("Relationship Data", response.data);

        // for(var i=0;i<response.data.consolView.length;i++){
        //
        //   response.data.consolView[i].totalCash=response.data.consolView[i].totalCash+response.data.consolView[i].p3CashBlncAmt
        // }
        setConsolidationData(response.data);
        setIsResponse(true);
        getColumnStateDb();
        setExpandedState(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };
  let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetconsolidationData();
        getColumnStateDb();

        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [userId]);

  // const signOut = () => {

  //     navigate("/");
  //     let token = JSON.parse(localStorage.getItem('token'));
  //     const postData = {};
  //     const config = {
  //       headers: {
  //         'authorization': `Bearer ${token.token}`,
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json'
  //       }

  //     };
  //     axios.post('/token/revoke',
  //       postData,
  //       config
  //     )
  //       .then((response) => {
  //         //
  //         // localStorage.setItem('token', '');
  //         //console.log(response);
  //         // navigate("/");

  //         // if (response.statusText === '') {

  //         // }

  //       })
  //       .catch((error) => {
  //         //
  //         console.log("my error is " + error);
  //       })

  //     // let tokenNew={token:''};
  //     // localStorage.setItem('token',JSON.stringify(tokenNew));
  //     // localStorage.setItem("AcctSelected",null);
  //     localStorage.removeItem('token');
  //     localStorage.clear();
  //     //firebaseApp.auth.signOut();

  //   }

  if (loading) {
    return (
      <div className="">
        <div className="container-fluid">
          <div class="table-responsive">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="align-middle">
                    {expandedState ? (
                      <>
                        <div
                          className="btn btn-outline-secondary btn-sm"
                          onClick={handleTriggerColapse}
                        >
                          <FaMinusSquare /> collapse
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="btn btn-outline-secondary btn-sm"
                          onClick={handleTriggerExpand}
                        >
                          <FaPlusSquare /> expand
                        </div>
                      </>
                    )}
                    <button
                      className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
                      onClick={handleTriggerColSave}
                    >
                      <FaSave /> save columns
                    </button>
                  </td>
                  {!showall &&
                  localStorage.getItem("pConsId") !== "0" &&
                  localStorage.getItem("SelAcctId") == 0 ? (
                    <>
                      <td class="align-middle">
                        <span
                          className="py-1"
                          style={{
                            color: themes === "dark" ? "white" : "#454545",
                          }}
                        >
                          As Of date:{" "}
                        </span>
                        <DatePicker
                          format="MM/dd/yyyy"
                          className="form-control form-control-sm"
                          size={"small"}
                          width={150}
                          value={asOfDt}
                          max={maxDate}
                          min={minDate}
                          onChange={(e) => {
                            setDate(e.value);
                            setPrcDt(e.value);
                          }}
                          formatPlaceholder={{
                            year: "yyyy",
                            month: "mm",
                            day: "dd",
                          }}
                        />
                      </td>
                      <td class="align-middle">
                        <span
                          className="py-1"
                          style={{
                            color: themes === "dark" ? "white" : "#454545",
                          }}
                        >
                          Price date:{" "}
                        </span>
                        <DatePicker
                          format="MM/dd/yyyy"
                          className="form-control form-control-sm"
                          size={"small"}
                          max={maxDate}
                          min={minDate}
                          width={150}
                          value={priceDt}
                          onChange={(e) => {
                            setPrcDt(e.value);
                          }}
                          formatPlaceholder={{
                            year: "yyyy",
                            month: "mm",
                            day: "dd",
                          }}
                        />
                      </td>
                      <td class="align-middle">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "0398fc",
                                "&.Mui-checked": {
                                  color: "#0398fc",
                                },
                              }}
                              checked={effectivecDtInd}
                              onChange={handleEffectiveDtInd}
                            />
                          }
                          style={{
                            color: themes === "dark" ? "white" : "#454545",
                          }}
                          label="Effective Date Indicator"
                        />
                      </td>
                      <td class="align-middle">
                        <input
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={formSubmit}
                          value="submit"
                        />
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                  <td class="align-middle">
                    <FormGroup>
                      <FormControlLabel
                        checked={showall}
                        disabled={
                          localStorage.getItem("pConsId") == 0 ? true : false
                        }
                        control={<BlueSwitch />}
                        onChange={handlechangeCheckbox}
                        label="Show All"
                        style={{ color: themes === "dark" ? "white" : "black" }}
                      />
                    </FormGroup>
                  </td>
                  <td class="align-middle">
                    <div
                      className="btn btn-outline-secondary btn-outline-0 btn-sm mx-2"
                      style={{ maxWidth: "100px" }}
                      onClick={handleExcelExport}
                    >
                      <FaFileExcel /> export
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <div className="">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="container-fluid">
        <Modal
          title="Validation"
          open={visible}
          onOk={(e) => {
            setVisible(false);
          }}
          onCancel={(e) => {
            setVisible(false);
          }}
          footer={[
            <Button
              key="Ok"
              onClick={(e) => {
                setVisible(false);
              }}
            >
              Ok
            </Button>,
          ]}
        >
          <div style={{ whiteSpace: "pre-line" }}>{DialogMsg}</div>
        </Modal>
        <div class="table-responsive">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="align-middle">
                  {expandedState ? (
                    <>
                      <div
                        className="btn btn-outline-secondary btn-sm"
                        onClick={handleTriggerColapse}
                      >
                        <FaMinusSquare /> collapse
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="btn btn-outline-secondary btn-sm"
                        onClick={handleTriggerExpand}
                      >
                        <FaPlusSquare /> expand
                      </div>
                    </>
                  )}
                  <button
                    className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
                    onClick={handleTriggerColSave}
                  >
                    <FaSave /> save columns
                  </button>
                </td>
                {!showall &&
                localStorage.getItem("pConsId") !== "0" &&
                localStorage.getItem("SelAcctId") == 0 ? (
                  <>
                    <td class="align-middle">
                      <span
                        className="py-1"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        As Of date:{" "}
                      </span>
                      <DatePicker
                        format="MM/dd/yyyy"
                        className="form-control form-control-sm"
                        size={"small"}
                        width={150}
                        value={asOfDt}
                        max={maxDate}
                        min={minDate}
                        onChange={(e) => {
                          setDate(e.value);
                          setPrcDt(e.value);
                        }}
                        formatPlaceholder={{
                          year: "yyyy",
                          month: "mm",
                          day: "dd",
                        }}
                      />
                    </td>
                    <td class="align-middle">
                      <span
                        className="py-1"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        Price date:{" "}
                      </span>
                      <DatePicker
                        format="MM/dd/yyyy"
                        className="form-control form-control-sm"
                        size={"small"}
                        max={maxDate}
                        min={minDate}
                        width={150}
                        value={priceDt}
                        onChange={(e) => {
                          setPrcDt(e.value);
                        }}
                        formatPlaceholder={{
                          year: "yyyy",
                          month: "mm",
                          day: "dd",
                        }}
                      />
                    </td>
                    <td class="align-middle">
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "0398fc",
                              "&.Mui-checked": {
                                color: "#0398fc",
                              },
                            }}
                            checked={effectivecDtInd}
                            onChange={handleEffectiveDtInd}
                          />
                        }
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                        label="Effective Date Indicator"
                      />
                    </td>
                    <td class="align-middle">
                      <input
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={formSubmit}
                        value="submit"
                      />
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td class="align-middle">
                  <FormGroup>
                    <FormControlLabel
                      checked={showall}
                      disabled={
                        localStorage.getItem("pConsId") == 0 ? true : false
                      }
                      control={<BlueSwitch />}
                      onChange={handlechangeCheckbox}
                      label="Show All"
                      style={{ color: themes === "dark" ? "white" : "black" }}
                    />
                  </FormGroup>
                </td>
                <td class="align-middle">
                  <div
                    className="btn btn-outline-secondary btn-outline-0 btn-sm mx-2"
                    style={{ maxWidth: "100px" }}
                    onClick={handleExcelExport}
                  >
                    <FaFileExcel /> export
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div className="row d-flex  justify-content-between">
            {isResponse === true ? (
              <ConsolidationGrid
                data={consolidationData.consolView}
                triggerColapse={triggerColapse}
                triggerExpand={triggerExpand}
                triggerExport={triggerExport}
                triggerColSave={triggerColSave}
                flag={isColumnSave}
              ></ConsolidationGrid>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsolidationRpt;
